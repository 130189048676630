var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "white-bg green-border-box" },
      [
        _c("div", { staticClass: "brand-head in-padd posRel" }, [
          _c("div", [
            _c("div", { staticClass: "profile-photo" }, [
              _c("img", {
                staticClass: "profile-photo-img green-border-box",
                attrs: { src: _vm.brand.logo + "?fit=crop&w=300&h=300" },
                on: {
                  error: function($event) {
                    return _vm.$common.getDefaultImage("user")
                  }
                }
              }),
              _vm._m(0)
            ]),
            _c("div", { staticClass: "afterImage" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "autosize",
                      rawName: "v-autosize",
                      value: 25,
                      expression: "25"
                    }
                  ],
                  staticClass: "brand-name"
                },
                [
                  _vm._v(
                    "\n              " + _vm._s(_vm.brand.name) + "\n          "
                  )
                ]
              ),
              _c("label", { staticClass: "w100 tagline-label" }, [
                _vm._v("Tagline")
              ]),
              _c("span", { staticClass: "tagline", attrs: { title: "" } }, [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.brand.tagline) +
                    "\n          "
                )
              ])
            ])
          ]),
          _c("div", { staticClass: "clearfix" }),
          _c("hr"),
          _c("div", { staticClass: "row login-details" }, [
            _c("div", { staticClass: "col-xs-12 col-sm-12" }, [
              _c("strong", [_vm._v("General Brand Email : ")]),
              _vm._v(_vm._s(_vm.brand.email) + "\n          "),
              _vm.brand.age_restricted == 1
                ? _c("strong", [
                    _c(
                      "span",
                      {
                        staticClass: "alcoholic pull-right",
                        staticStyle: {
                          background: "red",
                          padding: "4px",
                          "border-radius": "5px",
                          color: "white"
                        }
                      },
                      [_vm._v("AGE RESTRICTED")]
                    )
                  ])
                : _vm._e()
            ])
          ]),
          _c(
            "button",
            {
              staticClass: "btn-positive edit-btn",
              attrs: {
                type: "button",
                "data-toggle": "modal",
                "data-target": "#brandeditpopup"
              }
            },
            [_vm._v("EDIT")]
          )
        ]),
        _c("div", { staticClass: "clearfix" }),
        _c("div", { staticClass: "brand-body posRel" }, [
          _c("h3", [_vm._v("MAIN BRAND CONTACT")]),
          _c("div", [
            _c("div", { staticClass: "col-xs-12 col-md-4 part" }, [
              _c("label", [_vm._v("Name")]),
              _c("span", { attrs: { title: _vm.brand.contact_person_name } }, [
                _vm._v(_vm._s(_vm.brand.contact_person_name))
              ])
            ]),
            _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-5 part" }, [
              _c("label", [_vm._v("Email")]),
              _c("span", [_vm._v(_vm._s(_vm.brand.contact_person_email))])
            ]),
            _c("div", { staticClass: "col-xs-12 col-sm-6 col-md-3 part" }, [
              _c("label", [_vm._v("Mobile")]),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm._f("phone")(_vm.brand.contact_person_mobile_number)
                  )
                )
              ])
            ]),
            _c("div", { staticClass: "clearfix" })
          ])
        ]),
        _c("ChangeBrandPhotoPopup", {
          attrs: {
            popupId: "change-photo-popup",
            brandId: _vm.brand.id,
            oldImage: _vm.brand.logo
          },
          on: { changeImage: _vm.getBrandProfile }
        }),
        _c("BrandEditPopup", {
          attrs: {
            popupId: "brandeditpopup",
            brand: Object.assign({}, _vm.brand)
          },
          on: { profileUpdated: _vm.profileUpdatedHandler }
        })
      ],
      1
    ),
    _c("br"),
    _c("br"),
    _vm.$store.state.userData.role == "brand"
      ? _c("div", { staticClass: "green-border-box in-padd white-bg" }, [
          _c("h2", [_vm._v("Team Users")]),
          _vm.brandUsers.length > 0
            ? _c("table", { staticClass: "table table-striped" }, [
                _vm._m(1),
                _c(
                  "tbody",
                  _vm._l(_vm.brandUsers, function(brandUser, index) {
                    return _c("tr", { key: brandUser.id }, [
                      _c("th", { attrs: { scope: "row" } }, [
                        _vm._v(_vm._s(index + 1))
                      ]),
                      _c("td", [
                        _vm._v(_vm._s(brandUser.user.name)),
                        _vm.$store.state.userData.id == brandUser.user.id
                          ? _c("span", { staticStyle: { color: "grey" } }, [
                              _c("small", [_vm._v("(You)")])
                            ])
                          : _vm._e()
                      ]),
                      _c("td", [_vm._v(_vm._s(brandUser.user.email))]),
                      _c("td", [_vm._v(_vm._s(brandUser.user.mobile_number))]),
                      _c("td", [
                        _vm.$store.state.userData.id != brandUser.user.id
                          ? _c(
                              "button",
                              {
                                staticClass: "btn btn-negative",
                                on: {
                                  click: function($event) {
                                    return _vm.removeBrandUser(brandUser.id)
                                  }
                                }
                              },
                              [_vm._v("Revoke Access ")]
                            )
                          : _vm._e()
                      ])
                    ])
                  }),
                  0
                )
              ])
            : _vm._e(),
          !_vm.busy && (!_vm.invitations || _vm.invitations.length == 0)
            ? _c("h3", { staticClass: "text-center mt20" }, [
                _vm._v("\n      No Brand User Found\n    ")
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _c("br"),
    _c("br"),
    _vm.$store.state.userData.role == "brand"
      ? _c(
          "div",
          { staticClass: "green-border-box in-padd white-bg" },
          [
            _vm._m(2),
            _vm.invitations.length > 0
              ? _c("table", { staticClass: "table table-striped" }, [
                  _vm._m(3),
                  _c(
                    "tbody",
                    _vm._l(_vm.invitations, function(invitation, index) {
                      return _c("tr", { key: invitation.id }, [
                        _c("th", { attrs: { scope: "row" } }, [
                          _vm._v(_vm._s(index + 1))
                        ]),
                        _c("td", [_vm._v(_vm._s(invitation.email))]),
                        _c("td", [_vm._v(_vm._s(invitation.status))]),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              new Date(
                                invitation.expire_date_time
                              ).toDateString()
                            )
                          )
                        ]),
                        _c("td", [
                          invitation.status == "PENDING" ||
                          invitation.status == "DECLINED"
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-negative",
                                  staticStyle: { margin: "0" },
                                  attrs: {
                                    "data-target": "#delete-invitation-popup",
                                    "data-toggle": "modal"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.setActiveInvitationId(
                                        invitation.id
                                      )
                                    }
                                  }
                                },
                                [_vm._v("Remove ")]
                              )
                            : _vm._e(),
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-positive",
                              staticStyle: { margin: "0" },
                              attrs: {
                                "data-target": "#show-invitation-email-popup",
                                "data-toggle": "modal"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.setActiveInvitationContent(
                                    invitation
                                  )
                                }
                              }
                            },
                            [_vm._v("Copy Invitation Link")]
                          )
                        ])
                      ])
                    }),
                    0
                  )
                ])
              : _vm._e(),
            !_vm.busy && (!_vm.invitations || _vm.invitations.length == 0)
              ? _c("h3", { staticClass: "text-center mt20" }, [
                  _vm._v("\n      No Invitation sent.\n    ")
                ])
              : _vm._e(),
            _c("DeleteInvitationPopup", {
              attrs: {
                popupId: "delete-invitation-popup",
                invitationId: _vm.selectedInvitationId,
                brandId: _vm.$store.state.userData.brandId
              },
              on: { deleted: _vm.getBrandInvitationsList }
            }),
            _c("SendInvitationLink", {
              on: { invitationSent: _vm.getBrandInvitationsList }
            }),
            _c("email-content-popup", {
              attrs: {
                popupId: "show-invitation-email-popup",
                invitation: _vm.activeInvitation
              }
            })
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "a",
      {
        attrs: {
          "data-target": "#change-photo-popup",
          "data-toggle": "modal",
          href: "javascript:;"
        }
      },
      [_c("img", { attrs: { src: "/img/profile/edit_icon.png" } })]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("#")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Name")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Email")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Mobile Number")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Action")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-sm-12" }, [
        _c("h2", { staticClass: "pull-left" }, [_vm._v("Team Invitations")]),
        _c("button", { staticClass: "invitation-link-button" }, [
          _c(
            "span",
            {
              staticClass: "hidden-xs",
              attrs: {
                "data-toggle": "modal",
                "data-target": "#brand-invitation-link-modal"
              }
            },
            [_vm._v("Send Invitation Link")]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("#")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Email")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Status")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Expires On")]),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Action")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }