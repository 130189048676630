









































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class ChangeBrandPhotoPopup extends Vue {
  @Prop() popupId!: string
  @Prop({ default: '' }) oldImage!: string
  @Prop() brandId!: number
  public image = ''
  public imageSelected = false
  public myCroppa:any = {}
  public busy: boolean = false
  updateProfile () {
    this.busy = true
    this.generateImage()
    this.$validator.validateAll('change-image').then((result) => {
      if (result) {
        let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
        let user = { image: this.image }
        this.$store.dispatch('updateBrandImage', { id: this.brandId, user: user }).then(() => {
          popupCloseButton.click()
          // this.getUserByToken()
          this.resetForm()
          this.$notify({
            text: 'Image has been changed Successfully!',
            type: 'success'
          })
          this.$emit('changeImage')
        }).finally(() => {
          this.busy = false
        })
      } else {
        this.busy = false
      }
    }, () => { this.busy = false })
  }

  generateImage () {
    let url = this.myCroppa.generateDataUrl()
    if (!url) {
      return
    }

    this.image = url
  }

  CropEnable () {
    this.image = ''
    this.imageSelected = true
  }

  cropDisable () {
    this.image = ''
    this.imageSelected = false
  }

  resetForm () {
    this.myCroppa.remove()
    let myCroppa = this.myCroppa
    Object.assign(this.$data, (this.$options.data as any).apply(this))
    this.myCroppa = myCroppa
    this.$nextTick().then(() => this.$validator.errors.clear('change-image'))
  }
}
