<template>
    <div id="brand-invitation-link-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2><img src="/img/Alerts/plusbig_icon.png" /> Send Intivation Link To Email</h2>
                    <button ref="popupCloseButton" type="button" class="close" data-dismiss="modal" aria-label="Close">
                    </button>
                </div>
                <div class="modal-body">
                    <form v-on:submit.prevent="addBrand" data-vv-scope="send-invitation-link-form">
                      <div class="row">
                        <div class="col-md-12">
                          <input type="text" placeholder="End Email Id" name="email" v-model="email" v-validate="'required|email'" :class="{'input': true, 'is-danger': errors.has('send-invitation-link-form.email') }" />
                          <select name="roleId" v-model="roleId" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('send-invitation-link-form.email') }">
                              <option value="">Select Role</option>
                              <option v-for="role in roles" :key="role.id" :value="role.id">{{role.name}}</option>
                          </select>
                        </div>
                       <input type="submit" class="btn-lg-green" value="SEND INVITATION LINK" :disabled="submittingForm" />
                    </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'BrandPopup',
  data: function () {
    return {
      email: '',
      roleId: '',
      submittingForm: false,
      roles: []
    }
  },
  mounted () {
    this.getBrandRoles()
  },
  methods: {
    getBrandRoles () {
      this.$store.dispatch('getBrandRoles')
        .then(
          (response) => {
            this.roles = response.body.roles
          }
        )
    },
    addBrand () {
      this.$validator.validateAll('send-invitation-link-form').then((result) => {
        if (result) {
          this.submittingForm = true
          this.$store.dispatch('sendInvitationLilnk', { email: this.email, roleId: this.roleId, brandId: this.$store.state.userData.brandId, userName: this.$store.state.userData.name }).then(() => {
            this.$refs.popupCloseButton.click()
            this.$notify({ type: 'success', text: 'Invitation Link has been sent successfully!' })
            this.submittingForm = false
            this.$emit('invitationSent')
          }, (response) => {
            for (var key in response.body) {
              this.$notify({ type: 'error', text: 'Internal Server Error!' })
            }
            this.errorMessage = response.body.message
            this.submittingForm = false
          })
        }
      })
    },
    generateImage () {
      let url = this.myCroppa.generateDataUrl()
      if (!url) {
        return
      }

      this.brand.logo = url
    },
    CropEnable () {
      this.brand.logo = ''
      this.imageSelected = true
    },
    cropDisable () {
      this.brand.logo = ''
      this.imageSelected = false
    },
    resetForm () {
      this.myCroppa.remove()
      let myCroppa = this.myCroppa
      Object.assign(this.$data, this.$options.data.apply(this))
      this.myCroppa = myCroppa
      this.errors.clear('add-brand')
    }
  }
}
</script>

<style scoped>
select,
select:hover {
  border: 1px solid #6f3053;
  border-radius: 3px;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 20px;
  color: black;
  width: 100%;
  padding: 14px 42px 14px 16px;
  height: 50px;
  background-position: right 11px center;
  background-size: 20px 12px;
  background-image: url("/img/Create Pickl/selectproductDropdown_icon.png");
  background-color: white;
}
</style>
