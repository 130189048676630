var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "brand-invitation-link-modal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _c("button", {
              ref: "popupCloseButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                attrs: { "data-vv-scope": "send-invitation-link-form" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.addBrand($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-md-12" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|email",
                          expression: "'required|email'"
                        }
                      ],
                      class: {
                        input: true,
                        "is-danger": _vm.errors.has(
                          "send-invitation-link-form.email"
                        )
                      },
                      attrs: {
                        type: "text",
                        placeholder: "End Email Id",
                        name: "email"
                      },
                      domProps: { value: _vm.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email = $event.target.value
                        }
                      }
                    }),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.roleId,
                            expression: "roleId"
                          },
                          {
                            name: "validate",
                            rawName: "v-validate",
                            value: "required",
                            expression: "'required'"
                          }
                        ],
                        class: {
                          input: true,
                          "is-danger": _vm.errors.has(
                            "send-invitation-link-form.email"
                          )
                        },
                        attrs: { name: "roleId" },
                        on: {
                          change: function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.roleId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          }
                        }
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("Select Role")
                        ]),
                        _vm._l(_vm.roles, function(role) {
                          return _c(
                            "option",
                            { key: role.id, domProps: { value: role.id } },
                            [_vm._v(_vm._s(role.name))]
                          )
                        })
                      ],
                      2
                    )
                  ]),
                  _c("input", {
                    staticClass: "btn-lg-green",
                    attrs: {
                      type: "submit",
                      value: "SEND INVITATION LINK",
                      disabled: _vm.submittingForm
                    }
                  })
                ])
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("img", { attrs: { src: "/img/Alerts/plusbig_icon.png" } }),
      _vm._v(" Send Intivation Link To Email")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }