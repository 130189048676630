var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg modal-dialog-centered" },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h2", [_vm._v("Email Content")]),
              _c("button", {
                ref: "popupCloseButton",
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close"
                }
              })
            ]),
            _c("div", { staticClass: "modal-body" }, [
              _vm.invitation.email_content
                ? _c("p", {
                    staticStyle: { "text-align": "left" },
                    domProps: {
                      innerHTML: _vm._s(_vm.invitation.email_content)
                    }
                  })
                : _c("p", [_vm._v("Email Content Not Available")])
            ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }