









































import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeleteInvitation extends Vue {
  @Prop() popupId!: string
  @Prop() invitationId!: number
  @Prop() brandId!: number

  deleteInvitation () {
    let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
    this.$store.dispatch('deleteBrandInvitation', { brandId: this.brandId, invitationId: this.invitationId }).then(() => {
      this.$emit('deleted')
      popupCloseButton.click()
    }, () => popupCloseButton.click())
  }
}
