






























import Vue from 'vue'
import { Prop, Component } from 'vue-property-decorator'

@Component
export default class DeleteInvitation extends Vue {
  @Prop() invitation!: object
  @Prop() popupId!: string
}
