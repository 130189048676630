var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: _vm.popupId,
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "myLargeModalLabel",
        "aria-hidden": "true"
      }
    },
    [
      _c("div", { staticClass: "modal-dialog modal-dialog-centered" }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h2", [_vm._v("Change Picture")]),
            _c("button", {
              ref: "popupCloseButton",
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close"
              }
            })
          ]),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "form",
              {
                attrs: { "data-vv-scope": "change-image" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.updateProfile($event)
                  }
                }
              },
              [
                _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "col-xs-12 croppa-tip" }),
                  _c("div", { staticClass: "col-sm-6 text-center mb20" }, [
                    _c(
                      "div",
                      { staticClass: "upload-remove-container" },
                      [
                        _c("croppa", {
                          attrs: {
                            placeholder: "Choose a new image",
                            "initial-size": "contain",
                            "placeholder-font-size": 16,
                            "show-remove-button": false
                          },
                          model: {
                            value: _vm.myCroppa,
                            callback: function($$v) {
                              _vm.myCroppa = $$v
                            },
                            expression: "myCroppa"
                          }
                        }),
                        _vm.myCroppa.hasImage && _vm.myCroppa.hasImage()
                          ? _c("button", {
                              staticClass: "upload-remove",
                              attrs: { type: "button" },
                              on: {
                                click: function($event) {
                                  return _vm.myCroppa.remove()
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.image,
                          expression: "image"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      attrs: { type: "hidden", name: "image" },
                      domProps: { value: _vm.image },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.image = $event.target.value
                        }
                      }
                    }),
                    _c("br"),
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !(
                                _vm.myCroppa.hasImage && _vm.myCroppa.hasImage()
                              ) && _vm.errors.has("change-image.image"),
                            expression:
                              "!(myCroppa.hasImage && myCroppa.hasImage()) && errors.has('change-image.image')"
                          }
                        ],
                        staticClass: "error-msg"
                      },
                      [_vm._v(_vm._s(_vm.errors.first("change-image.image")))]
                    ),
                    _c("br")
                  ]),
                  _vm.oldImage && _vm.oldImage != ""
                    ? _c("div", { staticClass: "col-sm-6" }, [
                        _c("img", {
                          staticClass: "img-responsive center-block oldImage",
                          attrs: { src: _vm.oldImage }
                        }),
                        _c("h4", [_vm._v("Current Image")])
                      ])
                    : _vm._e()
                ]),
                _c("input", {
                  staticClass: "btn-lg-green",
                  attrs: { type: "submit", value: "SAVE", disabled: _vm.busy }
                })
              ]
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }