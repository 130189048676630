



















































import Vue from 'vue'
import { Prop, Component, Watch } from 'vue-property-decorator'
import { TheMask } from 'vue-the-mask'

@Component({
  components: {
    TheMask
  }
})
export default class BrandEditPopup extends Vue {
  @Prop() popupId!: string
  @Prop() brand!: any
  public mobile = ''
  public code = ''
  public submittingForm:boolean = false

  getMobile () {
    let phone = this.brand.contact_person_mobile_number
    if (phone === null || phone === undefined) {
      this.code = ''
      this.mobile = ''
      return
    }
    let start = phone.length - 10
    if (start < 0) start = 0
    this.code = phone.substring(0, start)
    if (this.code.length < 2) this.code = '+1'
    else if (!this.code.startsWith('+')) this.code = '+' + this.code
    this.mobile = phone.substring(start, start + 10)
  }

  @Watch('brand.id')
  onBrandChange (value: string, oldValue: string) {
    if (value !== oldValue) {
      this.getMobile()
    }
  }

  updateBrand () {
    this.$validator.validateAll('edit-brand').then((result) => {
      if (result) {
        this.submittingForm = true
        this.brand.contact_person_mobile_number = this.code + this.mobile
        this.$store.dispatch('updateBrandProfile', this.brand).then(() => {
          let popupCloseButton: HTMLElement = this.$refs.popupCloseButton as HTMLElement
          popupCloseButton.click()
          this.$notify({ type: 'success', text: 'Profile been updated successfully' })
          this.submittingForm = false
          this.$emit('profileUpdated')
        }, (response) => {
          for (var key in response.body) {
            this.$notify({ type: 'error', text: response.body[key][0] })
            let field = this.$validator.fields.find({ name: key, scope: 'edit-brand' })

            if (field) {
              this.$validator.errors.add({
                id: field.id,
                field: key,
                msg: response.body[key][0],
                scope: 'edit-brand'
              })
            }
          }
          this.submittingForm = false
        })
      } else if (this.$validator.errors.has('edit-brand.mobile:regex')) {
        this.$notify({ type: 'error', text: 'Please enter a valid mobile number' })
      }
    })
  }
}
