import { render, staticRenderFns } from "./BrandEditPopup.vue?vue&type=template&id=31673b53&scoped=true&"
import script from "./BrandEditPopup.vue?vue&type=script&lang=ts&"
export * from "./BrandEditPopup.vue?vue&type=script&lang=ts&"
import style0 from "./BrandEditPopup.vue?vue&type=style&index=0&id=31673b53&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31673b53",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/naveenkumar/Sites/pickl-web-app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31673b53')) {
      api.createRecord('31673b53', component.options)
    } else {
      api.reload('31673b53', component.options)
    }
    module.hot.accept("./BrandEditPopup.vue?vue&type=template&id=31673b53&scoped=true&", function () {
      api.rerender('31673b53', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Brand/BrandEditPopup.vue"
export default component.exports