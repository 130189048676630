























































































































import Vue from 'vue'
import Component from 'vue-class-component'
import ChangePasswordPopup from '@/components/common/ChangePasswordPopup.vue'
import ChangeBrandPhotoPopup from '@/components/common/ChangeBrandPhotoPopup.vue'
import BrandEditPopup from '@/components/Brand/BrandEditPopup.vue'
import SendInvitationLink from '@/components/Brand/SendInvitationLink.vue'
import DeleteInvitationPopup from '@/components/Brand/DeleteInvitationPopup.vue'
import EmailContentPopup from '@/components/Brand/EmailContentPopup.vue'
@Component({
  components: {
    ChangePasswordPopup,
    ChangeBrandPhotoPopup,
    BrandEditPopup,
    SendInvitationLink,
    DeleteInvitationPopup,
    EmailContentPopup
  }
})
export default class Details extends Vue {
  public brand = []
  public selectedInvitationId: number = 0
  public invitations: any[] = []
  public totalCount = 0
  public busy: boolean = false
  public brandUsers:any[] =[]
  public activeInvitation: any = { email: '', emailContent: '' }

  setActiveInvitationId (id: number) {
    this.selectedInvitationId = id
  }
  setActiveInvitationContent (invitation: any) {
    this.activeInvitation = invitation
  }

  removeBrandUser (brandUserId: any) {
    this.$store.dispatch('removeUserBrand', { id: brandUserId }).then((response) => {
      if (response.status === 204) {
        this.getbrandUsersList()
        this.$notify({
          text: 'User access has been revoked!',
          type: 'success'
        })
      }
    })
  }

  getBrandInvitationsList (clear = true) {
    this.busy = true
    this.$store.dispatch('getBrandInvitationsList', { brandId: this.$store.state.userData.brandId }).then((response) => {
      if (clear) this.invitations = []
      this.invitations.push(...response.body.accessInvitations)
      this.busy = false
    }, (response) => {
      this.busy = false
    })
  }
  getbrandUsersList (clear = true) {
    this.busy = true
    this.$store.dispatch('getBrandUsersList', { brandId: this.$store.state.userData.brandId }).then((response) => {
      if (clear) this.brandUsers = []
      console.log(response.body.brandUsers)
      this.brandUsers.push(...response.body.brandUsers)
      this.busy = false
    }, (response) => {
      this.busy = false
    })
  }
  changeImage (image: string) {
    this.$store.dispatch('updateProfile', { id: this.$store.state.userData.id, user: { image: image } }).then(() => {
      this.$notify({
        text: 'Profile Picture Updated Successfully!',
        type: 'success'
      })
    })
  }
  getUserByToken () {
    this.$store.dispatch('getUserData')
  }
  getBrandProfile () {
    this.$store.dispatch('getBrandProfile', this.$store.state.userData.brandId).then((response: any) => {
      this.brand = response.response.body.data
    })
  }
  profileUpdatedHandler () {
    this.getBrandProfile()
    this.getUserByToken()
  }
  mounted () {
    this.getBrandProfile()
    if (this.$store.state.userData.role === 'brand') {
      this.getBrandInvitationsList()
      this.getbrandUsersList()
    }
  }
}
